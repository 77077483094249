import Vue from "vue";
import { AxiosResponse } from "axios";
import { api, ECategoryCode, ICategorySmRequestStatusResponse, IStimulusProjectState } from "@/api/stimulus.api";

const projectStates = Vue.observable({ states: [] as IStimulusProjectState[] });

let pendingPromise: Promise<any> | null = null;

export const getProjectStates = async (): Promise<IStimulusProjectState[]> => {
    if (!projectStates.states.length) {
        pendingPromise = api
            .get(`/api/v1/stimulus/categories/${ECategoryCode.smProjectState}`)
            .then((response: AxiosResponse<ICategorySmRequestStatusResponse>) => {
                projectStates.states = response.data.data as IStimulusProjectState[];
                return projectStates.states;
            })
            .finally(() => (pendingPromise = null));
        return pendingPromise;
    } else {
        return projectStates.states;
    }
};
