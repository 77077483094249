import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('komis-accordion',{attrs:{"items":[{ title: _vm.$i18n.t('documents.upload.title'), expanded: false }]},scopedSlots:_vm._u([{key:"item-content-0",fn:function(){return [_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('komis-upload-files',{attrs:{"label":_vm.$i18n.t('documents.upload.label')},on:{"change":_vm.onFileChange}}),(!_vm.forcePublicState)?_c('komis-checkbox',{attrs:{"label":_vm.$i18n.t('file_upload.meta.is_public')},model:{value:(_vm.fileReference.isPublic),callback:function ($$v) {_vm.$set(_vm.fileReference, "isPublic", $$v)},expression:"fileReference.isPublic"}}):_vm._e(),_c('komis-heading5',{attrs:{"text":_vm.$i18n.t('file_upload.meta.lang')}}),_c(VBtnToggle,{model:{value:(_vm.fileReference.metadata.lang),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "lang", $$v)},expression:"fileReference.metadata.lang"}},[_c(VBtn,{attrs:{"outlined":"","small":"","value":"sk"}},[_vm._v(_vm._s(_vm.$i18n.t("file_upload.meta.sk")))]),_c(VBtn,{attrs:{"outlined":"","small":"","value":"en"}},[_vm._v(_vm._s(_vm.$i18n.t("file_upload.meta.en")))])],1),_c('komis-accordion',{attrs:{"items":[
                    { title: _vm.$i18n.t('file_upload.meta.titleSk'), expanded: true },
                    { title: _vm.$i18n.t('file_upload.meta.titleEn') } ]},scopedSlots:_vm._u([{key:"item-content-0",fn:function(){return [_c('komis-select',{attrs:{"items":_vm.attachmentTypes,"item-text":"name","item-value":"id","label":_vm.$i18n.t('file_upload.meta.attachmentType'),"clearable":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"select-item"},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.fileReference.metadata.attachmentType),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "attachmentType", $$v)},expression:"fileReference.metadata.attachmentType"}}),_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.nameSk') + ' *',"required":"","rules":[
                                    function (v) { return !!v || _vm.$i18n.t('file_upload.meta.validation.not_empty'); },
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.nameSk),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "nameSk", $$v)},expression:"fileReference.metadata.nameSk"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.keywordsSk'),"rules":[
                                    //(v) => !!v || $i18n.t('file_upload.meta.validation.not_empty'),
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.keywordsSk),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "keywordsSk", $$v)},expression:"fileReference.metadata.keywordsSk"}})],1)],1),_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.author') + ' *',"rules":[
                                    function (v) { return !!v || _vm.$i18n.t('file_upload.meta.validation.not_empty'); },
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.author),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "author", $$v)},expression:"fileReference.metadata.author"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.coauthor'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.coauthor),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "coauthor", $$v)},expression:"fileReference.metadata.coauthor"}})],1)],1),_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.copyrightSk'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.copyrightSk),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "copyrightSk", $$v)},expression:"fileReference.metadata.copyrightSk"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.sourceSk'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.sourceSk),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "sourceSk", $$v)},expression:"fileReference.metadata.sourceSk"}})],1)],1)]},proxy:true},{key:"item-content-1",fn:function(){return [_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.nameEn') + ' *',"required":"","rules":[
                                    function (v) { return !!v || _vm.$i18n.t('file_upload.meta.validation.not_empty'); },
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.nameEn),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "nameEn", $$v)},expression:"fileReference.metadata.nameEn"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.keywordsEn'),"rules":[
                                    //(v) => !!v || $i18n.t('file_upload.meta.validation.not_empty'),
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.keywordsEn),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "keywordsEn", $$v)},expression:"fileReference.metadata.keywordsEn"}})],1)],1),_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.author') + ' *',"rules":[
                                    function (v) { return !!v || _vm.$i18n.t('file_upload.meta.validation.not_empty'); },
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.author),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "author", $$v)},expression:"fileReference.metadata.author"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.coauthor'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.coauthor),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "coauthor", $$v)},expression:"fileReference.metadata.coauthor"}})],1)],1),_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.copyrightEn'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.copyrightEn),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "copyrightEn", $$v)},expression:"fileReference.metadata.copyrightEn"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.sourceEn'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.sourceEn),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "sourceEn", $$v)},expression:"fileReference.metadata.sourceEn"}})],1)],1)]},proxy:true}])}),_c('komis-button',{attrs:{"loading":_vm.loading,"disabled":_vm.loading || !_vm.valid,"module":"skcris","value":_vm.$i18n.t('documents.upload.button')},on:{"click":_vm.uploadFiles}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }