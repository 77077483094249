


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getStimulusHistory, IHistoryItem } from "@/api/stimulus.api";
import { IDatatableOptions } from "@/api/types";
import { EKomisAlertType, KomisAlert, KomisHeading4, KomisTable } from "@iway/spa-components";
import { DataTableHeader } from "vuetify";
import { ETypeCodes, getPdfLink } from "@/helpers/linkBuilder";

@Component({
    components: { KomisTable, KomisAlert, KomisHeading4 },
})
export default class StateHistoryTable extends Vue {
    @Prop({ required: true, type: String }) stimulusId!: string;
    @Prop({ required: false, type: Boolean, default: false }) enabled!: boolean;
    EKomisAlertType = EKomisAlertType;
    error = false;
    grantCode = ETypeCodes.Grants;
    getPdfLink = getPdfLink;
    historyTable: IDatatableOptions & { items: IHistoryItem[] } = {
        headers: [],
        items: [],
        loading: false,
        count: 0,
        limit: 100,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$i18n.t("history.state") as string,
                sortable: true,
                value: "state.name",
            },
            {
                text: this.$i18n.t("history.version") as string,
                sortable: true,
                value: "uri",
            },
            {
                text: this.$i18n.t("history.modify_date") as string,
                sortable: true,
                value: "modifyDate",
            },
            {
                text: this.$i18n.t("history.comment") as string,
                sortable: false,
                value: "reason",
            },
        ];
    }

    created() {
        this.historyTable.headers = this.tableHeaders;
        if (this.enabled) this.loadHistory();
    }

    mounted() {
        //
    }

    loadHistory() {
        this.historyTable.loading = true;
        getStimulusHistory(this.stimulusId)
            .then((items) => (this.historyTable.items = items))
            .catch(() => (this.error = true))
            .finally(() => (this.historyTable.loading = false));
    }

    @Watch("enabled")
    onFilterChanged(val: boolean) {
        if (val === true) {
            console.log("refreshed");
            this.loadHistory();
        }
    }
}
