



















import { Component } from "vue-property-decorator";
import { KomisAlert, KomisButton, KomisContentHeader } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import StimulusTable from "@/components/stimulus-list/StimulusTable.vue";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";
import { getStimulusRequestsStatus } from "@/api/stimulus.api";
import { isOriginFromPersonalSpace } from "@/mixins/Utils";

@Component({
    components: { StimulusTable, KomisContentHeader, KomisButton, KomisAlert },
})
export default class StimulusView extends BaseView {
    canCreate = false;

    created() {
        getStimulusRequestsStatus().then((status) => {
            this.canCreate = status.isOpen && this.isOriginFromPersonalSpace;
        });
    }

    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationStimulusList;
    }

    get pageTitle() {
        if (this.organizationRoute) {
            return this.$i18n.t("organization.list_title").toString();
        } else {
            return this.$i18n.t("stimulus.list").toString();
        }
    }

    get isOriginFromPersonalSpace(): boolean {
        return isOriginFromPersonalSpace();
    }

    mounted() {
        if (this.organizationRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.stimulus_list").toString(),
                to: { name: RouteNames.OrganizationStimulusList, params: { id: this.$route.params.organizationId } },
                disabled: false,
                link: true,
                exact: true,
            });
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list").toString(),
                to: { name: RouteNames.StimulusList },
                disabled: false,
                link: true,
                exact: true,
            });
        }
    }

    get backButtonRoute(): string {
        return this.$route.params.organizationId
            ? "/" +
                  this.$store.state.language.value +
                  "/register-organizacii/osobny-priestor-organizacie/" +
                  this.$route.params.organizationId
            : "";
    }

    get newStimulusLink(): string | false {
        return getNetgrifUrlByParams(
            "vytvorenie_ziadosti",
            "SKCRIS-028",
            { orgId: this.$route.params.organizationId },
            this.$store.state.language.value,
        );
    }
}
