




































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { IDatatableOptions } from "@/api/types";
import { KomisActionButton, KomisAlert, KomisButton, KomisTable, KomisHeading4 } from "@iway/spa-components";
import { ETypeCodes, getPdfLink } from "@/helpers/linkBuilder";
import { IProject, IEvaluationDetail } from "@/api/stimulus.api";

@Component({
    components: {
        KomisTable,
        KomisAlert,
        KomisActionButton,
        KomisButton,
        KomisHeading4,
    },
})
export default class StimulusProjectsTable extends Vue {
    @Prop(Object) data!: IEvaluationDetail;
    error = false;
    projectsDatatable: IDatatableOptions & { items: IProject[] } = {
        headers: [],
        limit: 10,
        count: 0,
        items: [],
        loading: false,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$i18n.t("projects.datatable.type") as string,
                sortable: false,
                value: "type",
            },
            {
                text: this.$i18n.t("projects.datatable.name") as string,
                sortable: false,
                value: "name",
            },
            {
                text: this.$i18n.t("projects.datatable.actions") as string,
                sortable: false,
                value: "actions",
            },
        ];
    }

    mounted(): void {
        console.log("data:", this.data);
        this.projectsDatatable.headers = this.tableHeaders;
        this.processDatatableResponse();
    }

    processDatatableResponse(): void {
        this.projectsDatatable.items = this.data.projects;
        this.projectsDatatable.count = this.data.projects.length;
    }

    stimulusProjectPdfRoute(item: any) {
        window.location.assign(getPdfLink(item.formId, ETypeCodes.GrantsProjects));
    }
}
