import Vue from "vue";

export interface IStimulusListFilterOptions {
    name: string | null; // nazov organizacie
    state: string | null; // IStimulusState.id => uuid
    startdate: string | null; // datum odoslania od (filter na skModifyDate)
    enddate: string | null; // datum odoslania do (filter na skModifyDate)
}

export const initialStimulusFilter = (): IStimulusListFilterOptions => {
    return {
        name: null, // nazov organizacie
        state: null, // stav ziadosti
        startdate: null, // datum odoslania od
        enddate: null, // datum odoslania do
    };
};

export const stimulusFilter: IStimulusListFilterOptions = Vue.observable(initialStimulusFilter());

export const prepareFilterForApi = (): string | undefined => {
    const filterForApi: string[] = [];
    Object.keys(stimulusFilter).forEach((keyName) => {
        const value = stimulusFilter[keyName];
        if (value || (typeof value === "string" && value.length)) {
            filterForApi.push(`${keyName}(${value})`);
        }
    });
    return filterForApi.length ? filterForApi.join(",") : undefined;
};
