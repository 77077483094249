











import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectsTable from "@/components/projects/ProjectsTable.vue";
import { IStimulusState } from "@/api/stimulus.api";

@Component({
    components: { ProjectsTable },
})
export default class ProjectsList extends Vue {
    @Prop(Object) stimulusState!: IStimulusState | null;
    @Prop(Boolean) organizationAdmin!: boolean;
    @Prop(Boolean) hasPermission!: boolean;
}
