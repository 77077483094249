var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.documentsDatatable.items.length && !_vm.attachmentTypesLoading)?_c('div',[_c('komis-table',_vm._b({key:"table--stimulus-documents",attrs:{"module":"skcris","countPagesItem":Math.ceil(_vm.documentsDatatable.count / _vm.documentsDatatable.limit),"hasType":false,"headers":_vm.documentsDatatable.headers,"items":_vm.documentsDatatable.items,"loading":_vm.documentsDatatable.loading,"locale":'sk',"multi-sort":true,"option":_vm.documentsDatatable.options,"totalItems":_vm.documentsDatatable.count,"item-key":"id"},on:{"update:option":function($event){return _vm.$set(_vm.documentsDatatable, "options", $event)},"optionChanged":_vm.onDatatableOptionsChanged},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.metadata.attachmentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAttachmentTypeLabel(item.metadata.attachmentType))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center flex-nowrap"},[_c('komis-action-button',{attrs:{"tooltip-text":_vm.$i18n.t('actions.detail'),"type":"show"},on:{"click":function($event){_vm.$router.push(_vm.getDetailRoute(item))}}}),_c('komis-action-button',{attrs:{"tooltip-text":_vm.$i18n.t('actions.download'),"type":"download"},on:{"click":function($event){return _vm.downloadFile(item)}}}),(_vm.canDelete)?_c('komis-action-button',{attrs:{"type":"delete","tooltip-text":_vm.$i18n.t('actions.delete')},on:{"click":function($event){return _vm.deleteDocument(item)}}}):_vm._e()],1)]}}],null,true)},'komis-table',_vm.$attrs,false)),_c('komis-dialog',_vm._g(_vm._b({ref:"confirmDeleteDialog",attrs:{"module":"skcris","title":_vm.$i18n.t('modals.confirm_delete.title'),"action-button-text":_vm.$i18n.t('modals.confirm_delete.button')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
return [_vm._v(" "+_vm._s(model)+" ")]}}],null,false,4196791121)},'komis-dialog',_vm.$attrs,false),_vm.$listeners))],1):(_vm.documentsDatatable.loading)?_c('komis-alert',{attrs:{"text":_vm.$i18n.t('loading_items')}}):(_vm.error)?_c('komis-alert',{attrs:{"type":"error","text":_vm.$i18n.t('loading_items_failed')}}):_c('komis-alert',{attrs:{"text":_vm.$i18n.t('no_results')}})}
var staticRenderFns = []

export { render, staticRenderFns }