import axios, { AxiosResponse } from "axios";
import store from "@/store";
import { IDatatableResponse, ILangCode, ILocalizedString } from "@/api/types";
import { DataOptions } from "vuetify";

const dmsXhrEndpoint = "/XhrFileUpload";
const dmsFileEndpoint = "/private/rest/hub/file";
const dmsFileListEndpoint = "/private/rest/hub/file/<product>/<module>/<moduleId>";

export const product = "skcris";
export const moduleName = "stimulus";

export interface IFiles {
    id: string;
    fileKey: string;
}

export type IFileMeta = {
    names: ILocalizedString[];
    keywords: ILocalizedString[];
    author: string;
    coauthor: string;
    copyright: ILocalizedString[];
    source: ILocalizedString[];
    lang: ILangCode.sk | ILangCode.en;
};

export interface IFile {
    product: string;
    module: string;
    moduleId: string;
    fileKey: string;
    parentId: string | null;
    metadata: Record<string, string | null> | null;
    isPublic: boolean;
}

export interface IFileDetail {
    id: number; // document ID
    name: string; // filename
    path: string; //"/files/skcris/organizations/7357de10-ec24-40ab-a350-78283b1bf964";
    url: string; // DMS API url + this url
    size: number;
    product: "skcris";
    module: "stimulus";
    metadata: IFileMeta;
    moduleId: string; // stimulus request id, organization id...
    type: {
        name: string; // example: "FILE"
        text: string; // example: "png"
    };
    uploaded: string; // iso date+time
    lastUpdate: string; // iso date+time
    uploader: {
        id: number;
        title: string;
        firstName: string;
        lastName: string;
        photo: string;
    };
    folder: boolean;
    public: boolean;
}

export interface IDocument {
    folder: boolean;
    id: number;
    lastUpdate: string; // iso date+time
    metadata: IFileMeta;
    module: "stimulus";
    moduleId: string; // stimulus id
}

export interface IPostFileResponse {
    success: boolean;
    message: string;
    data: IFileDetail;
}

export interface IFilesDatatableResponse extends IDatatableResponse {
    data: IFileDetail[];
}

const api = axios.create({ withCredentials: true });

api.interceptors.request.use((request) => {
    request.baseURL = store.state.envParams!["komis.vue.dms.api.rest.url"] || null;
    request.params = { ...request.params, langCode: store.state.language.valueForApi };
    return request;
});

// Add Xhr File
export const postFileToDms = (formData: FormData) => {
    return api.post(dmsXhrEndpoint, formData).then((response: AxiosResponse) => {
        console.log("postFileToDms:", response);
        return response.data;
    });
};

export const getFiles = (paginationOptions: DataOptions | undefined, id: string): Promise<IFilesDatatableResponse> => {
    let params = {};
    if (paginationOptions) {
        params = {
            limit: paginationOptions.itemsPerPage,
            offset: paginationOptions.page * paginationOptions.itemsPerPage - paginationOptions.itemsPerPage,
        };
    }

    return api
        .get(dmsFileListEndpoint.replace("<product>", product).replace("<module>", moduleName).replace("<moduleId>", id), {
            params,
        })
        .then((response: AxiosResponse<IFilesDatatableResponse>) => {
            return response.data;
        });
};

export const postFile = (file: IFile): Promise<IPostFileResponse> => {
    return api.post(dmsFileEndpoint + "/upload", file).then((response: AxiosResponse<IPostFileResponse>) => {
        console.log("DMS POST:", response);
        return response.data;
    });
};

export const deleteFile = async (id: number) => {
    const response = await api.delete(dmsFileEndpoint + "/" + id);
    console.log("DMS DELETE:", response);
    return response.data;
};

// TODO:
export const getDocuments = (paginationOptions: DataOptions, stimulusId: string): Promise<IFilesDatatableResponse> => {
    let params = {};
    if (paginationOptions) {
        params = {
            limit: paginationOptions.itemsPerPage,
            offset: paginationOptions.page * paginationOptions.itemsPerPage - paginationOptions.itemsPerPage,
        };
    }

    // https://skcris.komis-dev.cvtisr.sk/dms/private/rest/hub/file/skcris/stimulus/3ae150d8-b678-4707-b64e-f5b83f015e33
    return api
        .get(`${dmsFileEndpoint}/skcris/stimulus/${stimulusId}`, params)
        .then((response: AxiosResponse<IFilesDatatableResponse>) => response.data); // example: 3ae150d8-b678-4707-b64e-f5b83f015e33
};

export const getDocumentDetail = (documentId: number): Promise<IFileDetail> => {
    return api.get(`${dmsFileEndpoint}/show/detail/${documentId}`).then((response: AxiosResponse<IFileDetail>) => response.data);
};

export const generateDownloadLink = (link: string) => {
    return store.state.envParams!["komis.vue.dms.api.rest.url"] + link;
};
