import Vue from "vue";
import { AxiosResponse } from "axios";
import { api, ECategoryCode, ICategorySmRequestStatusResponse, IEvaluationState } from "@/api/stimulus.api";

const evaluationsStates = Vue.observable({ states: [] as IEvaluationState[] });

export enum EEvaluationStates {
    Vytvorena = "1201026001",
    Uzavreta = "1201026002",
    Zrusena = "1201026003",
}

let pendingPromise: Promise<any> | null = null;

export const getEvaluationsStates = async (): Promise<IEvaluationState[]> => {
    if (!evaluationsStates.states.length) {
        pendingPromise = api
            .get(`/api/v1/stimulus/categories/${ECategoryCode.smReviewStatus}`) //
            .then((response: AxiosResponse<ICategorySmRequestStatusResponse>) => {
                evaluationsStates.states = response.data.data as IEvaluationState[];
                return evaluationsStates.states;
            })
            .finally(() => (pendingPromise = null));
        return pendingPromise;
    } else {
        return evaluationsStates.states;
    }
};
