





































































































import { Component, Watch } from "vue-property-decorator";
import { KomisActionButton, KomisButton, KomisContentHeader, KomisDialog, KomisTable } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import { evaluationsFilter, IEvaluationsListFilterOptions } from "@/components/evaluations/evaluationsFilter";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";
import { IDatatableOptions } from "@/api/types";
import {
    deleteTask,
    EStimulusStateCodes,
    getEvaluationPdfLink,
    getStimulusDetail,
    getStimulusEvaluations,
    IEvaluation,
    IStimulus,
    IStimulusEvaluationsDatatableResponse,
    IEvaluationState,
    setTaskState,
} from "@/api/stimulus.api";
import { EEvaluationStates, getEvaluationsStates } from "@/api/evaluations-states";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import { DataOptions } from "vuetify";

@Component({
    methods: { getEvaluationPdfLink },
    components: { KomisContentHeader, KomisButton, KomisActionButton, KomisTable, KomisDialog },
})
export default class EvaluationsListView extends BaseView {
    evaluationsDatatable: IDatatableOptions & { items: IEvaluation[] } = {
        headers: [
            {
                text: this.$i18n.t("evaluations.datatable.date") as string,
                sortable: true,
                value: "createDate",
            },
            {
                text: this.$i18n.t("evaluations.datatable.evaluator") as string,
                sortable: true,
                value: "evaluatorName",
            },
        ],
        limit: 10,
        count: 0,
        items: [],
        loading: false,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    stimulusDetail: IStimulus | false = false;
    reopenState: IEvaluationState | null = null;

    EEvaluationStates = EEvaluationStates;

    get userCanReopen() {
        return userHasRole([USER_ROLE.SpravcaAgendyStimulyVav]);
    }

    get userCanAdd() {
        return this.stimulusDetail
            ? this.stimulusDetail.state.code === EStimulusStateCodes.PrebiehaHodnotenie &&
                  userHasRole([USER_ROLE.SpravcaAgendyStimulyVav])
            : false;
    }

    get userCanEdit() {
        return userHasRole(USER_ROLE.Hodnotitel);
    }

    get stimulusEvaluationsRoute() {
        return this.$route.name === RouteNames.StimulusEvaluations;
    }

    get allEvaluationsRoute() {
        return this.$route.name === RouteNames.AllEvaluations;
    }

    get requestId() {
        return this.$route.params.id;
    }

    async mounted() {
        if (this.stimulusEvaluationsRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list") as string,
                to: {
                    name: RouteNames.StimulusList,
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail") as string,
                to: {
                    name: RouteNames.StimulusDetail,
                    params: { id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list") as string,
                disabled: false,
                link: false,
                exact: true,
            });

            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.evaluatorType") as string,
                sortable: false,
                value: "evaluatorType",
            });

            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.evaluation") as string,
                sortable: false,
                value: "actions",
            });

            this.loadStimulusDetail();
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list") as string,
                disabled: false,
                link: false,
                exact: true,
            });
            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.requestor") as string,
                sortable: true,
                value: "requestor", // zatial nechodi z API
            });

            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.actions") as string,
                sortable: false,
                value: "actions",
            });
        }

        // Ak ma uzivatel rolu hodnotitel, moze vidiet iba vlastne hodnotenia
        if (userHasRole(USER_ROLE.Hodnotitel) && !userHasRole(USER_ROLE.SpravcaAgendyStimulyVav)) {
            this.filter.evaluatorname = this.$store.state.loggedUser.userId;
        }

        this.loadDataFromApi();

        const allEvaluationStates = await getEvaluationsStates();
        this.reopenState = allEvaluationStates.find((x) => x.code === EEvaluationStates.Vytvorena) || null;
    }

    get backButtonRoute() {
        return { name: RouteNames.StimulusDetail, params: { id: this.$route.params.id } };
    }

    get newEvaluationLink(): string | false {
        return this.$router.resolve({ name: RouteNames.StimulusEvaluationsNew }).href;
    }

    netgrifFormRoute(item: IEvaluation) {
        window.location.assign(
            getNetgrifUrlByParams(
                "zobrazenie_hodnotenia",
                "SKCRIS-031",
                { caseId: item.uri },
                this.$store.state.language.value,
            ) as string,
        );
    }

    getDetailRoute(item: IEvaluation) {
        return this.stimulusEvaluationsRoute
            ? { name: RouteNames.StimulusEvaluationsDetail, params: { id: this.$route.params.id, evaluationId: item.id } }
            : { name: RouteNames.EvaluationsDetail, params: { evaluationId: item.id } };
    }

    getDocumentsListRoute(item: IEvaluation) {
        return this.stimulusEvaluationsRoute
            ? {
                  name: RouteNames.StimulusEvaluationsDocuments,
                  params: { id: this.$route.params.id, evaluationId: item.id },
              }
            : { name: RouteNames.EvaluationsDocuments, params: { evaluationId: item.id } };
    }

    loadDataFromApi() {
        this.loadEvaluations(this.evaluationsDatatable.options);
    }

    loadEvaluations(options: DataOptions) {
        this.evaluationsDatatable.loading = true;
        const paginationOptions = {
            ...options,
        };
        this.$emit("paginationOptionsChanged", paginationOptions);
        getStimulusEvaluations(paginationOptions, this.requestId)
            .then((datatableResponse) => {
                this.processDatatableResponse(datatableResponse);
            })
            .finally(() => (this.evaluationsDatatable.loading = false));
    }

    loadStimulusDetail() {
        getStimulusDetail(this.$route.params.id).then((stimulusDetail) => (this.stimulusDetail = stimulusDetail));
    }

    processDatatableResponse(datatableResponse: IStimulusEvaluationsDatatableResponse) {
        this.evaluationsDatatable.items = datatableResponse.data;
        this.evaluationsDatatable.count = datatableResponse.count;
    }

    onDatatableOptionsChanged(options: DataOptions) {
        this.loadEvaluations(options);
    }

    submitEvaluation(item: IEvaluation) {
        console.log("Submit: (Zatial chýba api!)", item);
    }

    openEvaluation(item: IEvaluation) {
        if (item.id && this.reopenState?.id)
            setTaskState(item.id, this.reopenState.id)
                .then((response) => {
                    console.log(response);
                })
                .catch((err: any) => {
                    console.error("setProjectState:", err);
                })
                .finally(() => this.loadDataFromApi());
    }

    deleteEvaluation(item: IEvaluation) {
        (this.$refs.confirmDeleteDialog as KomisDialog<string>)
            .open(this.$i18n.t("modals.confirm_delete.task").toString())
            .then(() => {
                deleteTask(item.id, item.uri).then(() => {
                    this.loadEvaluations(this.evaluationsDatatable.options);
                });
            });
    }

    get filter(): IEvaluationsListFilterOptions {
        return evaluationsFilter;
    }
    @Watch("filter", { deep: true })
    onFilterChanged() {
        this.loadEvaluations(this.evaluationsDatatable.options);
    }

    downloadPdf(id: string) {
        window.open(getEvaluationPdfLink(id));
    }
}
