



























import { Component, Prop, Vue } from "vue-property-decorator";
import { KomisAlert, KomisButton, KomisSelect, KomisTextArea } from "@iway/spa-components";
import { getStimulusStates } from "@/api/stimulus-states";
import { IStimulusState, setStimulusState } from "@/api/stimulus.api";

@Component({
    components: { KomisButton, KomisSelect, KomisTextArea, KomisAlert },
})
export default class StimulusStateChange extends Vue {
    @Prop({ required: true, type: Array }) allowedStates!: string[];
    @Prop({ required: true, type: String }) stimulusId!: string;

    allStates: IStimulusState[] = [];
    allStatesLoading = true;

    state: string | null = null;
    note = "";

    saving = false;
    savingError = false;

    async mounted() {
        this.allStates = await getStimulusStates().finally(() => (this.allStatesLoading = false));
    }

    get states(): IStimulusState[] {
        return this.allStates.filter((state) => this.allowedStates.includes(state.id));
    }

    save() {
        if (this.state) {
            this.saving = true;
            this.savingError = false;

            setStimulusState(this.stimulusId, this.state, this.note)
                .catch(() => (this.savingError = true))
                .finally(() => {
                    this.saving = false;
                    this.$emit("refresh");
                });
        }
    }
}
