





































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { IDatatableOptions } from "@/api/types";
import { KomisActionButton, KomisAlert, KomisButton, KomisDialog, KomisTable, LetterFilter } from "@iway/spa-components";
import { IStimulusListFilterOptions, stimulusFilter } from "@/components/stimulus-list/stimulusFilter";
import { RouteNames } from "@/router";
import {
    deleteFile,
    generateDownloadLink,
    getDocuments,
    IDocument,
    IFileDetail,
    IFilesDatatableResponse,
} from "@/api/documents.api";
import { getAttachmentTypes, IAttachmentType } from "@/api/stimulus.api";

@Component({
    components: {
        KomisTable,
        KomisAlert,
        LetterFilter,
        KomisActionButton,
        KomisDialog,
        KomisButton,
    },
})
export default class DocumentsTable extends Vue {
    @Prop({ required: true, type: String }) stimulusId!: string;
    @Prop(Boolean) canDelete!: boolean;

    RouteNames = RouteNames;
    error = false;

    isOrganizationAdmin: boolean | null = null;
    documentsDatatable: IDatatableOptions & { items: IFileDetail[] } = {
        headers: [],
        limit: 10,
        count: 0,
        items: [],
        loading: false,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    attachmentTypes: IAttachmentType[] = [];
    attachmentTypesLoading = false;

    created() {
        getAttachmentTypes()
            .then((types) => (this.attachmentTypes = types))
            .finally(() => (this.attachmentTypesLoading = false));
    }

    get tableHeaders(): DataTableHeader[] {
        const headers = [
            {
                text: this.$i18n.t("documents.datatable.name") as string,
                sortable: true,
                value: "name",
            },
            {
                text: this.$i18n.t("documents.datatable.attachmentType") as string,
                sortable: true,
                value: "metadata.attachmentType",
            },
            {
                text: this.$i18n.t("documents.datatable.last_change") as string,
                sortable: true,
                value: "lastUpdate",
            },
            {
                text: this.$i18n.t("documents.datatable.filetype") as string,
                sortable: true,
                value: "type.text",
            },
            {
                text: this.$i18n.t("documents.datatable.size") as string,
                sortable: true,
                value: "size",
            },
            {
                text: this.$i18n.t("documents.datatable.actions") as string,
                sortable: false,
                value: "actions",
            },
        ];
        /*if (!this.organizationRoute) {
            headers.unshift({
                text: this.$i18n.t("stimulus.datatable.organization") as string,
                sortable: true,
                value: "organization.name",
            });
        }*/

        return headers;
    }

    /*
      ROUTES  ==========================================================
     */

    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationDocuments;
    }

    get evaluationsRoute() {
        return this.$route.name === RouteNames.EvaluationsDocuments;
    }

    get organizationId(): string | null {
        return this.organizationRoute ? this.$route.params.organizationId : null;
    }

    getProjectsListRoute(item: IDocument) {
        if (this.organizationRoute) {
            return {
                name: RouteNames.OrganizationStimulusProjectsList,
                params: { organizationId: this.organizationId, id: item.id },
            };
        } else {
            return { name: RouteNames.ProjectsList, params: { id: item.id } };
        }
    }

    getEvaluationsListRoute(item: IDocument) {
        // TODO
        return this.organizationRoute
            ? { name: RouteNames.OrganizationStimulusEvaluations, params: { organizationId: this.organizationId, id: item.id } }
            : { name: RouteNames.StimulusEvaluations, params: { id: item.id } };
    }

    getDetailRoute(item: IDocument) {
        if (this.evaluationsRoute) {
            return { name: RouteNames.EvaluationsDocumentDetail, params: { id: item.moduleId, documentId: item.id } };
        } else {
            return this.organizationRoute
                ? {
                      name: RouteNames.OrganizationDocumentDetail,
                      params: { organizationId: this.organizationId, id: item.moduleId, documentId: item.id },
                  }
                : { name: RouteNames.DocumentDetail, params: { id: item.moduleId, documentId: item.id } };
        }
    }

    mounted(): void {
        this.loadDocuments(this.documentsDatatable.options);

        this.documentsDatatable.headers = this.tableHeaders;
    }

    get filter(): IStimulusListFilterOptions {
        return stimulusFilter;
    }
    @Watch("filter", { deep: true })
    onFilterChanged() {
        this.loadDocuments(this.documentsDatatable.options);
    }

    processDatatableResponse(datatableResponse: IFilesDatatableResponse): void {
        this.documentsDatatable.items = datatableResponse.data;
        this.documentsDatatable.count = datatableResponse.count;
    }

    loadDocuments(paginationOptions: DataOptions | undefined = undefined): void {
        this.documentsDatatable.loading = true;
        this.error = false;

        if (paginationOptions) {
            getDocuments(paginationOptions, this.stimulusId)
                .then((datatableResponse) => {
                    this.processDatatableResponse(datatableResponse);
                })
                .catch((err: any) => {
                    console.error("loadStimulus -> getStimulus() error", err);
                    this.error = true;
                })
                .finally(() => (this.documentsDatatable.loading = false));
        }
    }

    public reload(): void {
        this.loadDocuments(this.documentsDatatable.options);
    }

    onDatatableOptionsChanged(options: DataOptions): void {
        this.loadDocuments(options);
    }

    // TODO:
    deleteDocument(document: IFileDetail) {
        (this.$refs.confirmDeleteDialog as KomisDialog<string>)
            .open(
                this.$i18n.t("modals.confirm_delete.document", {
                    document: document.name,
                }).toString(),
            )
            .then(() => {
                deleteFile(document.id).then(() => {
                    this.loadDocuments(this.documentsDatatable.options);
                });
            });
    }

    downloadFile(item: IFileDetail) {
        console.log("download file");
        window.location.href = generateDownloadLink(item.url);
    }

    getAttachmentTypeLabel(id: string) {
        return this.attachmentTypes.find((attachmentType) => attachmentType.id === id)?.name || "?";
    }
}
