







import { Component, Vue } from "vue-property-decorator";
import { USER_ROLE, userHasRole, VALIDATION_TYPE } from "@/helpers/user";
import { RouteNames } from "@/router";
import { IMenuItem, KomisSidebarHeader, KomisSidebarMenu } from "@iway/spa-components";

@Component({
    components: { KomisSidebarHeader, KomisSidebarMenu },
})
export default class Navigation extends Vue {
    //userHasRole = userHasRole;
    USER_ROLE = USER_ROLE;
    RouteNames = RouteNames;

    get items(): IMenuItem[] {
        let items: IMenuItem[] = [];
        if (
            userHasRole([USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav], VALIDATION_TYPE.OneOf) &&
            !this.$route.params.organizationId
        ) {
            items.push({
                text: this.$i18n.t("sidebar_navigation.items.stimulus_list").toString(),
                to: { name: RouteNames.StimulusList },
            });
        }

        if (
            userHasRole(
                [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.RegistrovanyPouzivatel],
                VALIDATION_TYPE.OneOf,
            ) &&
            this.$route.params.organizationId
        ) {
            items.push({
                text: this.$i18n.t("sidebar_navigation.items.stimulus_list").toString(),
                to: { name: RouteNames.OrganizationStimulusList, params: { id: this.$route.params.organizationId } },
            });
        }

        if (
            userHasRole(
                [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.Hodnotitel],
                VALIDATION_TYPE.OneOf,
            ) &&
            this.$route.name !== RouteNames.OrganizationStimulusList &&
            this.$route.name !== RouteNames.OrganizationStimulusDetail &&
            this.$route.name !== RouteNames.OrganizationDocumentDetail &&
            this.$route.name !== RouteNames.OrganizationStimulusEvaluations &&
            this.$route.name !== RouteNames.OrganizationDocuments &&
            this.$route.name !== RouteNames.OrganizationStimulusProjectsList
        ) {
            items.push({
                text: this.$i18n.t("sidebar_navigation.items.evaluations_list") as string,
                to: { name: RouteNames.AllEvaluations },
            });
        }

        return items;
    }
}
