









import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/Navigation.vue";
import { stimulusDocument } from "./document-detail";
import { IKomisInfoboxItem, KomisInfobox, KomisSidebarHeader } from "@iway/spa-components";
import { getAttachmentTypes, IAttachmentType } from "@/api/stimulus.api";

@Component({
    components: {
        Navigation,
        KomisSidebarHeader,
        KomisInfobox,
    },
})
export default class DocumentDetailSidebar extends Vue {
    get stimulusDocument() {
        return stimulusDocument.detail;
    }

    attachmentTypes: IAttachmentType[] = [];

    created() {
        getAttachmentTypes().then((types) => (this.attachmentTypes = types));
    }

    get items(): IKomisInfoboxItem[] {
        if (this.stimulusDocument && this.stimulusDocument.metadata && Object.keys(this.stimulusDocument?.metadata).length) {
            return Object.keys(this.stimulusDocument?.metadata).map((key) => {
                let textValue = this.stimulusDocument?.metadata[key];
                if (key === "attachmentType") {
                    textValue = this.attachmentTypes.find((type) => type.id === textValue)?.name || "-";
                }
                return {
                    label: this.$i18n.t(`file_upload.meta.${key}`).toString(),
                    text: textValue,
                };
            });
        }

        return [];
    }
}
