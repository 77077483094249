import axios, { AxiosResponse } from "axios";
import store from "@/store";
import { DataOptions } from "vuetify";
import { IDatatableResponse } from "@/api/types";
import { prepareFilterForApi } from "@/components/stimulus-list/stimulusFilter";
import { evaluationsFilterForApi } from "@/components/evaluations/evaluationsFilter";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";
import { EEvaluationStates } from "./evaluations-states";

const api = axios.create({ withCredentials: true });

/*
    TODO:
     my notes
     http://skcris.komis-dev.cvtisr.sk/orgapi/api/v1/stimulus/categories/smReviewStatus
 */

api.interceptors.request.use((request) => {
    request.baseURL = store.state.envParams?.["komis.vue.config.organization.api.url"] || null;
    request.params = { ...request.params, langCode: store.state.language.valueForApi };
    return request;
});

export { api };

/*
    Typescript types
 */

export interface IOrganization {
    id: string; // organization Id (cfOrgUnitId)
    name: string;
}

export interface IProject {
    id: string;
    name: string;
}

export interface IStimulusState {
    // skState
    id: string; // uuid
    name: string;
    code?: EStimulusStateCodes;
}

export interface IStimulusProjectState {
    // skState
    id: string; // uuid
    name: string;
    code?: EStimulusProjectStateCodes;
}

export interface IStimulusEvaluator {
    // skPersId
    id: string; // uuid
    name: string;
}

export interface IStimulus {
    id: string; // id stimulu
    code: number;
    formId: string; // pdf form id
    organization: IOrganization;
    state: IStimulusState;
    createDate: string; // iso date; Dátum vytvorenia žiadosti (skStartDate - najstarší záznam - keď bola žiadosť vytvorená)
    sendDate: string; // iso date; Dátum odoslania (skStartDate - najmladší záznam so stavom Podaná)
    modifyDate: string; // iso date; Datum modifikacie formularovych dat (skModifyDate)
    uri: string | null; // (skURI) Referencia na zobrazenie vykazu v Netgrif (id procesu) - moze byt NULL (tj. neexistuje v Netgrif, migrovany zo stareho systemu)
    allowedStates: string[];
}

export interface IEvaluation {
    id: string;
    projectId: string;
    createDate: string;
    evaluatorName: string;
    evaluatorType: string;
    uri: string;
}

export interface IEvaluationDetail {
    id: string; // smReview.smReviewId
    organization: IOrganization;
    creationDate: string; // datum vytvorenia ziadosti smReview.smCreateDate
    requestState: IStimulusState; // posledny stav ziadosti(nie hodnotenia)
    state: IEvaluationState;
    reviewer: string; // meno reviewer-a
    requestId: string; // smReview.smFormId
    projectRequestId: string; // id ziadosti projektu ak sa jedna o ziadost projektu
    projects: IProject[];
    json: boolean; // true ak je v smReview."smJson" ulozeny json
    uri: string; // smReview."skProcessId" - netgrif referencia
}

export interface IEvaluationState {
    // skState
    id: string; // uuid
    name: string;
    code: EEvaluationStates;
}

export interface ITask {
    id: string | null; // smReviewId - generovane, musi byt null pri vytvareni, v response uz musi byt naplnene
    formId: string; // id hodnotenej žiadosti UUID
    projId: string; // id hodnotenej žiadosti projektu UUID
    evaluatorTypeId: string; // typ hodnotiteľa UUID
    evaluatorId: string; // id hodnotiteľa UUID
    templateId: string; // id šablóny UUID
    templateCode: string;
    uri: string; // netgrif referencia
}

// from: http://skcris.komis-dev.cvtisr.sk/orgapi/api/v1/stimulus/categories/smProjectType
export enum EProjectTypeCodes {
    ProjektZakladnehoVyskumu = "1201029001",
    ProjektPriemyselnehoVyskumu = "1201029002",
    ProjektExperimentalnehoVyvoja = "1201029003",
    StudiaRealizovatelnosti = "1201029004",
}

export interface IProjectType {
    id: string;
    name: string;
    // code: EProjectTypeCodes;
}

export interface IStimulusProjectsApiItem {
    date: string;
    id: string;
    modifyDate: string;
    name: string;
    organization: IOrganization;
    project: IProject;
    startDate: string;
    state: IStimulusProjectState;
    type: IProjectType;
    uri: string;
}

// from: http://skcris.komis-dev.cvtisr.sk/orgapi/api/v1/stimulus/categories/smRequestStatus
export enum EStimulusStateCodes {
    // group I
    Rozpracovana = "1201025001",
    VratenaNaDoplnenie = "1201025002",
    Podana = "1201025003",
    Doplnena = "1201025004",
    KontrolaNalezitostiZiadosti = "1201025005",
    Zrusena = "1201025010",
    // group II
    PrebiehaHodnotenie = "1201025006",
    Schvalena = "1201025007",
    SchvalenaNefinancovana = "1201025008",
    Neschvalena = "1201025009",
}

export enum EStimulusProjectStateCodes {
    Rozpracovany = "1201036001",
    Kompletny = "1201036002",
    Zrušeny = "1201036003",
}

export interface IStimulusDatatableResponse extends IDatatableResponse {
    data: IStimulus[];
}

export interface IProjectsDatatableResponse extends IDatatableResponse {
    data: IStimulusProjectsApiItem[];
}

export interface IStimulusEvaluationsDatatableResponse extends IDatatableResponse {
    data: IEvaluation[];
}

// from /api/v1/stimulus/categories/
export enum ECategoryCode {
    prProjProfit = "prProjProfit",
    prProjOutTarget = "prProjOutTarget",
    smRequestStatus = "smRequestStatus",
    smReviewStatus = "smReviewStatus",
    smReviewType = "smReviewType",
    smAttachmentType = "smAttachmentType",
    smProjectType = "smProjectType",
    smFixedAssets = "smFixedAssets",
    smImmaterialAssets = "smImmaterialAssets",
    smPublType = "smPublType",
    smOutputCateg = "smOutputCateg",
    cfLegalOrgForm = "cfLegalOrgForm",
    smRelation = "smRelation",
    smProjectState = "smProjectState",
    smRequestTemplate = "smRequestTemplate",
    smProjectTemplate = "smProjectTemplate",
    smCosts = "smCosts",
}

export interface IHistoryItem {
    id: string;
    modifyDate: string; // iso date
    reason: string;
    state: IStimulusState;
    uri: string;
}

/*
    API calls
 */

// Zoznam ziadosti o stimuly - paginated
export const getStimulus = async (
    paginationOptions: DataOptions,
    organizationId: string | null = null,
): Promise<IStimulusDatatableResponse> => {
    const sortBy: string[] = [];
    paginationOptions.sortBy.forEach((column, index) => {
        const order: string = paginationOptions.sortDesc[index] ? "desc" : "asc";
        // sortBy.push(`${order}(${column})`);
        if (!organizationId && column === "organization.name") {
            sortBy.push(`${order}(name)`);
        }
        if (column === "createDate") {
            sortBy.push(`${order}(startdate)`);
        }
        if (column === "sendDate") {
            sortBy.push(`${order}(modifydate)`);
        }
        if (column === "state.name") {
            sortBy.push(`${order}(state)`);
        }
    });

    const endpoint = organizationId ? `/api/v1/stimulus/organizations/${organizationId}` : "/api/v1/stimulus";
    return api
        .get(endpoint, {
            params: {
                limit: paginationOptions.itemsPerPage,
                offset: paginationOptions.page * paginationOptions.itemsPerPage - paginationOptions.itemsPerPage,
                sortBy: sortBy.length ? sortBy.join(",") : undefined,
                filter: prepareFilterForApi(),
            },
        })
        .then((response: AxiosResponse<IStimulusDatatableResponse>) => response.data);
};

// Ciselniky stimuly
export interface ICategorySmRequestStatusResponse {
    id: string; // category id
    name: string; // category name, example: "Stav žiadosti"
    code: ECategoryCode;
    data: IStimulusState[] | IEvaluationState[] | IStimulusProjectState[];
}

// Detail ziadosti o stimuly
export const getStimulusDetail = (requestId: string): Promise<IStimulus> => {
    return api.get(`/api/v1/stimulus/${requestId}/basic`).then((response: AxiosResponse<IStimulus>) => {
        return response.data;
    });
};

export const getStimulusState = (requestId: string): Promise<IStimulusState> => {
    return api.get(`/api/v1/stimulus/${requestId}/state`).then((response: AxiosResponse<IStimulusState>) => {
        return response.data;
    });
};

// Zoznam verzii ziadosti
export const getStimulusHistory = (stimulusId: string): Promise<IHistoryItem[]> => {
    return api.get(`/api/v1/stimulus/${stimulusId}/history`).then((response: AxiosResponse<IHistoryItem[]>) => response.data);
};

// Zoznam projektov k ziadosti o stimuly
export const getProjectOfStimulus = (stimulusId: string, paginationOptions: DataOptions): Promise<IProjectsDatatableResponse> => {
    const sortBy: string[] = [];
    paginationOptions.sortBy.forEach((column, index) => {
        const order: string = paginationOptions.sortDesc[index] ? "desc" : "asc";
        sortBy.push(`${order}(${column})`);
    });

    return api
        .get(`/api/v1/stimulus/${stimulusId}/projects`, {
            params: {
                limit: paginationOptions.itemsPerPage,
                offset: paginationOptions.page * paginationOptions.itemsPerPage - paginationOptions.itemsPerPage,
                sortBy: sortBy.length ? sortBy.join(",") : undefined,
            },
        })
        .then((response: AxiosResponse<IProjectsDatatableResponse>) => response.data);
    // paginated = limit, offset, sortBy
};

// Zmena stavu ziadosti o Stimuly VaV
export const setStimulusState = (stimulusId: string, stateId: string, skReason: string) => {
    return api.put(`/api/v1/stimulus/${stimulusId}/state/${stateId}`, {
        skReason: skReason,
    });
};

export const setEvaluationsState = (id: string, stateId: string, skReason: string) => {
    return api.put(`/api/v1/stimulus/tasks/${id}/state/${stateId}`, {
        skReason: skReason,
    });
};

export const deleteStimulus = (stimulusId: string) => {
    return api.delete(`/api/v1/stimulus/${stimulusId}`);
};

export const deleteProject = (projectId: string) => {
    return api.delete(`/api/v1/stimulus/projects/${projectId}`);
};

export interface IAttachmentType {
    id: string;
    name: string;
    code: string;
}

interface IAttachmentTypesResponse {
    data: IAttachmentType[];
}
export const getAttachmentTypes = (): Promise<IAttachmentType[]> => {
    // https://kb.interway.sk/pages/viewpage.action?pageId=93224991 - vyberte typ prilohy
    return api
        .get("/api/v1/stimulus/categories/smAttachmentType")
        .then((response: AxiosResponse<IAttachmentTypesResponse>) => response.data.data);
};

// https://jira.interway.sk/browse/KOMIS-11178
export interface IStimulusRequestsStatus {
    active: boolean;
    startDate: string | null;
    endDate: string | null;
    isOpen: boolean;
}
export const getStimulusRequestsStatus = (): Promise<IStimulusRequestsStatus> => {
    return api.get("/api/v1/stimulus/requests/public").then((response: AxiosResponse<IStimulusRequestsStatus>) => response.data);
};

// Stimulus tasks list api
export const getStimulusEvaluations = (
    paginationOptions: DataOptions,
    requestId: string | undefined,
): Promise<IStimulusEvaluationsDatatableResponse> => {
    const sortBy: string[] = [];
    paginationOptions.sortBy.forEach((column, index) => {
        const order: string = paginationOptions.sortDesc[index] ? "desc" : "asc";
        sortBy.push(`${order}(${column})`);
    });

    let params = {};
    if (paginationOptions) {
        params = {
            limit: paginationOptions.itemsPerPage,
            offset: paginationOptions.page * paginationOptions.itemsPerPage - paginationOptions.itemsPerPage,
            sortBy: sortBy.length ? sortBy.join(",") : undefined,
            filter: evaluationsFilterForApi(),
        };
    }

    return api
        .get(`/api/v1/stimulus/${requestId ? requestId + "/" : ""}tasks`, { params })
        .then((response: AxiosResponse<IStimulusEvaluationsDatatableResponse>) => {
            return response.data;
        });
};

// Stimulus tasks list api
export const getEvaluationsDetail = (requestId: string): Promise<IEvaluationDetail> => {
    return api.get(`/api/v1/stimulus/tasks/${requestId}/basic`).then((response: AxiosResponse<IEvaluationDetail>) => {
        return response.data;
    });
};

export const getSmReviewTypes = (): Promise<IAttachmentType[]> => {
    return api
        .get("/api/v1/stimulus/categories/smReviewType")
        .then((response: AxiosResponse<IAttachmentTypesResponse>) => response.data.data);
};

export const getTaskProjects = (id: string): Promise<IProject[]> => {
    return api
        .get(`/api/v1/stimulus/${id}/projects`, {
            params: {
                limit: 10,
                offset: 0,
            },
        })
        .then((response: AxiosResponse<IProject[]>) => (response.data as any).data);
    //return api.get(`/api/v1/stimulus/${id}/projects/simple`).then((response: AxiosResponse<IProject[]>) => response.data);
};

export const getSmProjectTemplates = (): Promise<IAttachmentType[]> => {
    return api
        .get("/api/v1/stimulus/categories/smProjectTemplate")
        .then((response: AxiosResponse<IAttachmentTypesResponse>) => response.data.data);
};

// Post task by netgrif
export const postTask = async (task: ITask, organizationId: string, grantId: string) => {
    const postTaskUrl = getNetgrifUrlByParams(
        "vytvorenie_hodnotenia_post_na_url",
        "SKCRIS-031",
        { orgId: organizationId, stimulusId: grantId as string },
        store.state.language.value as string,
    ) as string;

    const response = await axios.post(postTaskUrl, task);
    return response.data;
};

// Change task state
export const setTaskState = (taskId: string, stateId: string) => {
    return api.put(`/api/v1/stimulus/tasks/${taskId}/state/${stateId}`);
};

// Change project state
export const setProjectState = (projectId: string, stateId: string) => {
    return api.put(`/api/v1/stimulus/projects/${projectId}/state/${stateId}`);
};

// Delete task by netgrif
export const deleteTask = async (taskId: string, caseId: string) => {
    const postTaskUrl = getNetgrifUrlByParams(
        "mazanie_hodnotenia",
        "SKCRIS-031",
        { taskId: taskId, caseId: caseId },
        store.state.language.value as string,
    ) as string;

    const response = await axios.delete(postTaskUrl);
    return response.data;
};

// Import stimulus project to SKCRIS
export const postProjectToSkcris = (projectId: string) => {
    return api.post(`/api/v1/stimulus/projects/${projectId}/import`);
};

export const getEvaluationPdfLink = (taskId: string) => {
    const baseLink = store.state.envParams?.["komis.vue.config.organization.api.url"] || null;
    const langCode = store.state.language.valueForApi;
    return baseLink + `/api/v1/stimulus/tasks/${taskId}/pdf?langCode=${langCode}`;
};
